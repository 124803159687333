import {useHistory, useLocation, Redirect} from "react-router-dom";
import React, {useState, useEffect} from "react";
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useAuth, Storage} from "@/services";
import {useLayout} from "@/layouts/Layout";
import {Link} from "react-router-dom";
import CheckOut from "./create/Checkout";
import Parking from "./create/Parking";
import Addons from "./create/Addons";
import Quote from "./create/Quote";


function Create(props) {
    const {t} = useTranslation();
    let auth = useAuth();
    let layout = useLayout();

    const route = useLocation();
    const history = useHistory();

    const [bookingChannel, setBookingChannel] = useState(null);

    const [site, __site] = useState(route.state && route.state.site);

    const [booking, setBooking] = useState(undefined);

    const bookingKey = route.state && route.state.bookingKey || null;
    const stage = route.state && route.state.stage || 'parking';

    const [errors, setErrors] = useState({});
    const [wait, setWait] = useState(false);

    const setStage = (stage) => {
        history.push(route.pathname, {
            ...route.state,

            bookingKey,
            stage,
        });
    };

    const updateBooking = (attributes, stage) => {
        const updatedBooking = {...booking, ...attributes};
        nextPage(updatedBooking, stage);
    };

    const nextPage = (updatedBooking, stage) => {
        if (!updatedBooking.key) {
            updatedBooking.key = Math.random();
        }

        Storage.Session.setItem('new_booking', updatedBooking);
        setBooking(updatedBooking);

        if (bookingKey != updatedBooking.key) {
            history.replace(route.pathname, {
                ...route.state,

                bookingKey: updatedBooking.key,
            });
        }

        if (stage) {
            setStage(stage);
        }
    }

    const setBookingErrors = (errors) => {
        setErrors(errors);

        updateBooking({addons: []})
        setStage('parking');
    };

    useEffect(() => {
        if (booking === undefined) {
            Storage.Session.getItem('new_booking')
                .then(value => {
                    if (value && bookingKey && value.key == bookingKey) {
                        setBooking(value);
                    }

                    setBooking(route.state && route.state.booking || {site_id: site && site.id});
                });
        }

    }, [booking]);


    useEffect(() => {
        if (!site) {
            history.push('/find-a-site');
        }

        if (booking?.id && ['approved', 'started', 'completed'].includes(booking.status)) {
            history.push(`/bookings/${booking.id}`, {booking});
        }
    });

    useEffect(() => {
        if (auth.userChannel && booking?.id) {
            const update = ({booking: updates}) => booking.id == updates.id && updateBooking(updates);

            auth.userChannel.bind('booking', update)

            return _ => auth.userChannel.unbind('booking', update);
        }
    }, [auth.userChannel, booking]);


    useEffect(() => {
        layout.scrollTop();
    }, [stage]);

    if (booking === undefined) {
        return <></>;
    }

    if (!site) {
        // These don't seem to work
        return <Redirect push={true} to="/find-a-site"/>;
    }


    if (booking.id) {
        if (['approved', 'started', 'completed'].includes(booking.status)) {
            // These don't seem to work
            return <Redirect to={`/bookings/${booking.order_id}`}/>;
        }

        return (
            <div className="container-fluid d-flex justify-content-center align-items-center" style={{height: "100%"}}>
                <Card border="light">
                    <Card.Body className="text-center">
                        <i className="bi bi-truck" style={{fontSize: "10rem"}}/>
                        <Card.Title>{t('waiting_approval_title')}</Card.Title>
                        <Card.Text>
                            {t('order_success_waiting_approval', {siteName: site.name})}
                        </Card.Text>
                        <div className="d-grid gap-2">
                            <Link variant="primary" className="btn btn-primary" to="/">{t('go_to_dashboard')}</Link>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
    }


    if (stage == 'parking') {
        return (
            <Parking {...{
                booking,
                errors,
                setBookingErrors,
                site,
                onSubmit: (values) => updateBooking(values, 'addons'),
                wait,
            }} />
        );

    }


    if (stage == 'addons') {
        return (
            <Addons onSubmit={(values) => {
                updateBooking(values, 'quote')
            }} {...{booking, site, errors, setBookingErrors, wait}} />
        );

    }

    if (stage == 'quote') {
        return (
            <Quote onSubmit={(values) => {
                updateBooking(values, 'checkout')
            }} {...{booking, site, errors, setBookingErrors, wait}} />
        );

    }

    if (stage == 'checkout') {
        return <CheckOut {...{
            site,
            booking,
            updateBooking: (booking) => updateBooking(booking),
            setBookingErrors,
            wait
        }}/>;
    }
}

export default Create;
