import {useTranslation}                from "react-i18next";
import {useAuth}                       from "@/services/Auth";
import {useParams, Link}                     from "react-router-dom";
import React, {useEffect, useState, useCallback}    from "react";
import LoadingPage                     from "@/components/LoadingPage";
import NumberFormat from 'react-number-format';
import * as moment from 'moment-timezone';
import { Badge, Button, Card, Col, Row, Form, Table } from "react-bootstrap";
import QRCode from 'qrcode';
import { Helmet } from "react-helmet";
import BookingRequestOptions from "./components/RequestOptions";
import useMoment from "@/hooks/useMoment";

function BookingShow({ location }) {
    const {t, i18n} = useTranslation();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let auth = useAuth();
    let {id} = useParams();

    const {momentLocal} = useMoment()

    const [booking, setBooking] = useState(location.state && location.state.booking);
    const [restrictedCategories, setRestrictedCategories] = useState({});
    const [src, setSrc] = useState(null);

    const [canUpdate, setCanUpdate] = useState(false);
    const [loading, setLoading] = useState(true);


    const mergeBooking = useCallback((updates) => {
        if(booking.id == updates.id)
        {
            setBooking({ ...booking, ...updates });
            setSrc(null);
        }
    }, [booking]);


    useEffect(() => {
        console.log(booking);
        if(!booking || booking.id != id)
        {
            auth.getRequest('/bookings/' + id)
                .then(response => {
                    setSrc(null);
                    setBooking(response.data.booking);
                    setRestrictedCategories(response.data.restricted_categories);
                    setCanUpdate(response.data.can_update);
                })

        }
    }, [booking, id]);

    useEffect(() => {
        if(auth.userChannel && booking?.id)
        {
            const update = ({ booking: updates }) => mergeBooking(updates);

            auth.userChannel.bind('booking', update)

            return _ => auth.userChannel.unbind('booking', update);
        }
    }, [auth.userChannel, booking]);

    useEffect(() => {
        if(booking && !src && booking.token)
        {
            QRCode.toDataURL(JSON.stringify({
                booking: {
                    id: booking.id,
                    token: booking.token,
                }
            })).then(data => {
                setSrc(data);
            })
        }
    }, [booking, src])

    if(!booking || (booking.token && !src))
    {
        return <LoadingPage/>;
    }

    var
    order_ids = [],
    net_total = 0,
    gross_total = 0,
    vat_total;

    let isAdr = false;
    let isOut = false;

    let qrBgColor = '';

    booking?.order_items?.forEach && booking?.order_items?.forEach(orderItem => {
        net_total += orderItem.net_amount;
        gross_total += orderItem.total_amount;
        if( !order_ids.includes(orderItem.order_number) ){
            order_ids.push(orderItem.order_number)
        }
        if (orderItem.category_id == restrictedCategories?.adr){
            isAdr = true;
        }
        if (
            orderItem.category_id == restrictedCategories?.long_bay ||
            orderItem.category_id == restrictedCategories?.wide_bay
        ) {
            isOut = true;
        }
    });

    if (isOut) {
        qrBgColor = '#79ddff';
    }

    if (isAdr) {
        qrBgColor = '#921313';
    }

    vat_total = gross_total - net_total;
    // Pre-book -> 116145
    // Instant -> 00458c


    return (
        <Card className="mx-2 my-2 p-2 order-card">
            <Helmet>
                <title>{booking.booking_number + ''} / {t('drawer_link_titles.bookings')} - {t('app')}</title>
            </Helmet>

            {booking.token && !booking.is_driver_less && (
                <Card.Subtitle className="d-flex justify-content-center" style={{width: '100%', backgroundColor: qrBgColor, paddingTop: '1rem'}}>
                    <div className="qr-code-contend d-flex text-center flex-column">
                        <img src={src}/>

                        <h4 className="text-uppercase">{t('booking_id')}: {booking.booking_number}</h4>
                    </div>
                </Card.Subtitle>
            ) || (
                <Card.Subtitle className="d-flex justify-content-between flex-md-row flex-column" style={{width: '100%'}}>
                    <h4 className="text-primary">{t('booking_id')} - {booking.booking_number}</h4>
                    {
                        (canUpdate && booking.status === 'pending')
                        &&
                        <BookingRequestOptions {...{ booking, updatedBooking: setBooking, setLoading }} />
                    }
                </Card.Subtitle>
                )}

            <Card.Body className="px-2">
                {booking.token && !booking.is_driver_less && (
                    <h6 className="font-weight-bold mb-4">{t('present_qr_code_for_payment')}</h6>
                ) || ''}

                <Row className="mt-2">
                    <Col md={3} sm={4} className="col-6">
                        <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                            <Form.Label column className="col-12">
                                <small> {t('order_id')}</small>
                            </Form.Label>
                            <Col className="col-12">
                                <h6>{order_ids.join(',')}</h6>
                            </Col>
                        </Form.Group>
                    </Col>
                    {booking?.vehicle?.fleet && (
                        <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                                <Form.Label column className="col-12">
                                    <small>{t('fleet_name')}</small>
                                </Form.Label>
                                <Col className="col-12">
                                    {booking.vehicle.fleet.id && (
                                        <Link to={`/fleets/${booking.vehicle.fleet.id}`} className="h6">
                                            {booking.vehicle.fleet.name}
                                        </Link>
                                    ) || (
                                        <h6>{booking.vehicle.fleet.name}</h6>
                                    )}
                                </Col>
                            </Form.Group>
                        </Col>
                    )}
                    {booking.user && booking.user.id != auth.user.id && (
                        <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                                <Form.Label column className="col-12">
                                    <small>{t('driver')}</small>
                                </Form.Label>
                                <Col className="col-12">
                                    <h6>
                                        {booking.user?.id && <Link to={`/users/${booking.user?.id}`}>{booking.user?.name}</Link> || booking.user?.name}
                                    </h6>
                                </Col>
                            </Form.Group>
                        </Col>
                    )}


                    {booking.site && (
                        <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                                <Form.Label column className="col-12">
                                    <small> {t('site')}</small>
                                </Form.Label>
                                <Col className="col-12">
                                    <h6>
                                        {booking.site?.id && <Link to={`/sites/${booking.site?.id}`}>{booking.site?.name}</Link> || booking.site?.name}
                                    </h6>
                                </Col>
                            </Form.Group>
                        </Col>
                    )}

                    {booking.from_datetime && (
                        <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                                <Form.Label column className="col-12">
                                    <small> {t('from')}</small>
                                </Form.Label>
                                <Col className="col-12">
                                    <h6>{booking.from_datetime && moment.utc(booking.from_datetime).tz(timezone).format(t('formats.datetime'))}</h6>
                                </Col>
                            </Form.Group>
                        </Col>
                    ) || ''}

                    {booking.to_datetime && (
                        <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                                <Form.Label column className="col-12">
                                    <small> {t('to')}</small>
                                </Form.Label>
                                <Col className="col-12">
                                    <h6>{booking.to_datetime && moment.utc(booking.to_datetime).tz(timezone).format(t('formats.datetime'))}</h6>
                                </Col>
                            </Form.Group>
                        </Col>
                    ) || ''}



                    {!booking.from_datetime ?

                        <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                                <Form.Label column className="col-12">
                                    <small> {t('booking.datetime')}</small>
                                </Form.Label>
                                <Col className="col-12">
                                    <h6 className="font-weight-normal">{booking.created_at && momentLocal(booking.created_at).tz(timezone).format(t('formats.datetime'))}</h6>
                                </Col>
                            </Form.Group>
                        </Col>
                        : ''}

                    {booking.vehicle?.registration_number && (
                        <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                                <Form.Label column className="col-12">
                                    <small> {t('vehicle_registration_number')}</small>
                                </Form.Label>
                                <Col className="col-12">
                                    <h6>{booking.vehicle?.registration_number}</h6>
                                </Col>
                            </Form.Group>
                        </Col>
                    ) || ''}


                    {typeof booking.has_trailer !== 'undefined' && (
                        <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                                <Form.Label column className="col-12">
                                    <small> {t('trailer')}</small>
                                </Form.Label>
                                <Col className="col-12">
                                    <h6>{booking.has_trailer && t('yes') || t('no')}</h6>
                                </Col>
                            </Form.Group>
                        </Col>
                    ) || ''}
                </Row>

                <Row>
                    <Col md={3} sm={4} className="col-6">
                        <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                            <Form.Label column className="col-12">
                                <small>
                                    {t('status')}</small>
                            </Form.Label>
                            <Col className="col-12">
                                <h6>
                                    <Badge pill bg={booking.status === 'pending' ? 'warning' : (booking.status === 'disapproved' || booking.status === 'rejected') ? 'danger' : 'success'} text={booking.status === 'pending' ? 'dark' : 'light'}>
                                        {t(`order_status.${booking.status}`)}
                                    </Badge>
                                </h6>
                            </Col>
                        </Form.Group>
                    </Col>

                    {booking.site && (
                        <>
                        <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                                <Form.Label column className="col-12">
                                    <small>{t('latitude')}</small>
                                </Form.Label>
                                <Col className="col-12">
                                    <h6>{booking.site.latitude}</h6>
                                </Col>
                            </Form.Group>
                        </Col>

                        <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextCompanyName">
                                <Form.Label column className="col-12">
                                    <small> {t('longitude')}</small>
                                </Form.Label>
                                <Col className="col-12">
                                    <h6>{booking.site.longitude}</h6>
                                </Col>
                            </Form.Group>
                        </Col>
                        </>
                    )}


                    {booking.type_str &&
                        <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextTo">
                                <Form.Label column className="col-12">
                                    <small> {t('booking_type')}</small>
                                </Form.Label>
                                <Col className="col-12">
                                    <h6>{t(`booking_types.${booking.type_str}`)}</h6>
                                </Col>
                            </Form.Group>
                        </Col>}

                </Row>

                <Row>
                    {
                        (booking.vehicle_checked_in_at) &&
                        <Col className="col-6 mt-2 p-0">
                            <div className="me-auto">
                                <div className="text-black font-weight-bold order-heading">{t('checked_in_at')}</div>
                                <h5 className="font-weight-normal">

                                    <Badge bg="success">
                                        {moment.utc(booking.vehicle_checked_in_at).tz(timezone).format(t('formats.datetime'))}
                                    </Badge>
                                </h5>
                            </div>
                        </Col>
                    }
                    {
                        booking.vehicle_checked_out_at &&
                        <Col className="col-6 mt-2 p-0">
                            <div className="me-auto">
                                <div className="text-black font-weight-bold order-heading">{t('checked_out_at')}</div>
                                <h5 className="font-weight-normal">
                                    <Badge bg="warning" text="dark">
                                        {moment.utc(booking.vehicle_checked_out_at).tz(timezone).format(t('formats.datetime'))}
                                    </Badge>
                                </h5>
                            </div>
                        </Col>
                    }
                </Row>

                {auth.user?.is_driver && (
                    <>
                    {!booking.instant_order_token && booking.site  && (
                        <Row className="mx-0 mt-2">
                            <Col className="col-12 mt-2 p-0">
                                <div className="d-grid gap-2">
                                    <a className="btn btn-primary" href={`https://google.com/maps/dir/?api=1&destination=${booking.site.latitude},${booking.site.longitude}`} target="_blank">{t('get_directions')}</a>
                                </div>
                            </Col>
                        </Row>
                    )}

                    {booking?.order_items?.map && (
                        <Row className="mx-0 mt-2">
                            <Col className="col-6 mt-2 p-0">
                                <div className="text-black font-weight-bold order-heading mb-2">{t('addons_services')}</div>
                                <div className="d-flex flex-column">
                                    {
                                        booking.order_items?.map(orderItem => (
                                            <div className="font-weight-normal me-4 my-1" key={orderItem.id}>
                                                {
                                                    orderItem.item_claimed_at ?
                                                    <div className="d-flex align-items-center mb-1" key={orderItem.id}>
                                                        <img className="service-icon" src={`/images/facilities/${orderItem.category_id}.svg`}/>
                                                        <span className="mx-1 text-nowrap facility-name-site-card"><strike>{orderItem.name}</strike></span>
                                                    </div>
                                                                              :
                                                    <div className="d-flex align-items-center mb-1" key={orderItem.id}>
                                                        <img className="service-icon" src={`/images/facilities/${orderItem.category_id}.svg`}/>
                                                        <span className="mx-1 text-nowrap facility-name-site-card">{orderItem.name}</span>
                                                    </div>
                                                }
                                            </div>
                                        ))
                                    }</div>
                            </Col>
                        </Row>
                    )}
                    </>
                ) || (booking?.order_items?.map && (
                    <Row className="mt-2">
                        <Col className="col-12">
                            <p><small>{t('items')}</small></p>
                            <Table striped responsive bordered hover size="sm" className="">
                                <thead>
                                <tr>
                                    <th>
                                        <div style={{minWidth: "150px"}}>{t('description')}</div>
                                    </th>
                                    <th>{t('used')}</th>
                                    <th className="text-end">
                                        <div style={{minWidth: "100px"}}>{t('net_amount')}</div>
                                    </th>
                                    <th className="text-end">
                                        <div style={{minWidth: "100px"}}>{t('vat_rate')}</div>
                                    </th>
                                    <th className="text-end">
                                        <div style={{minWidth: "100px"}}>{t('vat')}</div>
                                    </th>
                                    <th className="text-end">
                                        <div style={{minWidth: "100px"}}>{t('total')}</div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                    {booking.order_items?.map((orderItem, index) => (
                                        <tr key={index}>
                                            <td>{orderItem.name || t('parking')}</td>
                                            <td><Form.Check defaultChecked={orderItem.item_claimed_at != null} type="checkbox" disabled/></td>
                                            <td className="text-end">
                                                <NumberFormat value={orderItem.net_amount} displayType={'text'} thousandSeparator={true} suffix={` ${booking.site.currency}`} decimalScale={2} fixedDecimalScale={true}/>
                                            </td>
                                            <td className="text-end">
                                                <NumberFormat value={100 * ((orderItem.total_amount / orderItem.net_amount) - 1)} displayType={'text'} thousandSeparator={true} suffix={` %`} decimalScale={2} fixedDecimalScale={true}/>
                                            </td>
                                            <td className="text-end">
                                                <NumberFormat value={orderItem.total_amount - orderItem.net_amount} displayType={'text'} thousandSeparator={true} suffix={` ${booking.site.currency}`} decimalScale={2} fixedDecimalScale={true}/>
                                            </td>
                                            <td className="text-end">
                                                <NumberFormat value={orderItem.total_amount} displayType={'text'} thousandSeparator={true} suffix={` ${booking.site.currency}`} decimalScale={2} fixedDecimalScale={true}/>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <th>{t('total')}</th>
                                        <th>&nbsp;</th>
                                        <th className="text-end">
                                            <NumberFormat value={net_total} displayType={'text'} thousandSeparator={true} suffix={` ${booking.site.currency}`} decimalScale={2} fixedDecimalScale={true}/>
                                        </th>
                                        <th>&nbsp;</th>
                                        <th className="text-end">
                                            <NumberFormat value={vat_total} displayType={'text'} thousandSeparator={true} suffix={` ${booking.site.currency}`} decimalScale={2} fixedDecimalScale={true}/>
                                        </th>
                                        <th className="text-end">
                                            <NumberFormat value={gross_total} displayType={'text'} thousandSeparator={true} suffix={` ${booking.site.currency}`} decimalScale={2} fixedDecimalScale={true}/>
                                        </th>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                ))}
            </Card.Body>
        </Card>
    )
}

export default BookingShow;
