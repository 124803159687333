import {useHistory, useLocation, useParams} from "react-router-dom";
import React, {useEffect, useCallback, useState, useRef} from "react";
import {useLayout} from "@/layouts/Layout";
import LoadingPage from "@/components/LoadingPage";
import {useTranslation} from "react-i18next";
import {Button, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import {useAuth} from "@/services/Auth";
import DistanceMatrixServiceComponent from "./components/DistanceMatrixServiceComponent";
import {GoogleMap, InfoWindow, LoadScript, Marker, useJsApiLoader} from '@react-google-maps/api';
import SiteMapCard from "./components/SiteMapCard";
import SiteFilter from "./components/SiteFilter";
import {bearing, hashJourney, parseCoordinate, distance} from "@/services/Sites";
import * as JourneyStore from "@/services/JourneyStore";
import {Helmet} from "react-helmet";
import SiteCard from "../../../components/SiteCard";
import { ErrorBoundary, LEVEL_ERROR } from '@rollbar/react';


function FindSite(props) {
    const {t, i18n} = useTranslation();

    let mapAPIKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;


    let auth = useAuth();

    const route = useLocation();
    const layout = useLayout();
    const history = useHistory();

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: mapAPIKey
    })

    const [loading, setLoading] = useState(true);

    const [showFilter, setShowFilter] = useState(false);

    const [sites, setSites] = useState(route.state && route.state.sites || null);
    const [filterOptions, setFilterOptions] = useState(route.state && route.state.filterOptions || false);
    const [searchQuery, setSearchQuery] = useState('');
    const [siteNameSearch, setSiteNameSearch] = useState('');

    const [query, setQuery] = useState(route.state && route.state.query || {});

    const [map, setMap] = useState(null);

    const [defaultProps, setDefaultProps] = useState(route.state && route.state.defaultProps || {
        center: {
            lat: 51.5287718,
            lng: -0.2416788,
        },
        zoom: 6
    });

    const [currentLocation, setCurrentLocation] = useState(false);
    const [destinations, setDestinations] = useState([]);
    const [destinationStart, setDestinationStart] = useState(0);
    const [loadDistanceMatrix, setLoadDistanceMatrix] = useState(false);

    const [listView, setListView] = useState(route.state?.isList ?? false);

    const processJournies = useRef(null);

    processJournies.current = (sites) => {

        return new Promise(resolve => {
            var changed = false;
            const checks = [];


            sites.forEach(site => {

                if (currentLocation) {
                    site.bearing = bearing(currentLocation, site);
                    site.direction = Math.round(site.bearing / 45) % 8;


                    const check = JourneyStore.get(currentLocation, site)
                        .then(journey => {
                            if (journey != site.journey) {
                                changed = true;
                            }

                            site.journey = journey;
                        });

                    checks.push(check);
                } else {
                    site.bearing = undefined;
                    site.direction = undefined;
                    site.journey = undefined;
                }

            });

            if (checks.length > 0) {
                Promise.all(checks).then(_ => resolve(changed))
            } else {
                resolve(changed);
            }
        });


    };

    const getSites = useCallback((query) => {
        setSiteNameSearch(query.site_name);
        if (sites === false) {
            return;
        }

        setSites(false);

        query = query || {};

        const params = {...query};

        if (params.address && params.address.map) {
            params.address = params.address.map(_ => _.value);
        }


        params.include = 'all';

        auth.getRequest('/sites', params)
            .then(response => {
                const sites = response.data.sites.map(site => {
                    return site;
                });

                setQuery(query);

                processJournies.current && processJournies.current(sites)
                    .then(changed => {
                        setSites(sites);
                    })
                || setSites(sites);


            })
    });

    const onMapLoaded = useCallback((map) => {
        if (defaultProps) {
            map.setZoom(defaultProps.zoom);
            map.panTo(defaultProps.center);
        }

        setMap(map);
    });
    const onMapUnload = useCallback(() => setMap(null));

    const onMapIdle = () => {
        if (map.getCenter()) {
            setDefaultProps({
                center: {
                    lat: map.getCenter().lat(),
                    lng: map.getCenter().lng(),
                },
                zoom: map.getZoom()
            });
        }
    }

    const onFilter = (values) => {
        const prev = {...query},
            next = {...values};

        delete prev.max_journey_duration;
        delete next.max_journey_duration;

        if (JSON.stringify(prev) == JSON.stringify(next)) {
            setQuery(values);
        } else {
            getSites(values);
        }

        setShowFilter(false);
    };


    const buildDestinations = useCallback(() => {
        let destinationList = [];
        const checks = [];

        sites && sites.sort((a, b) => distance(currentLocation, a) - distance(currentLocation, b)) && sites.forEach(site => {
            const check = JourneyStore.get(currentLocation, site)
                .then(result => {
                    if (typeof result === 'undefined') {
                        destinationList.push(parseCoordinate(site))
                    }
                });

            checks.push(check);
        });

        Promise.all(checks).then(_ => {
            if (!loadDistanceMatrix && destinationList.length) {
                setLoadDistanceMatrix(true);
                setDestinations(destinationList);
            }
        });

    }, [currentLocation, loadDistanceMatrix, sites]);

    const updateDistanceInformation = useCallback((updatedDestinations) => {
        JourneyStore.merge(updatedDestinations).then(_ => {
            setLoadDistanceMatrix(false);

            processJournies.current && processJournies.current(sites)
                .then(changed => {
                    if (changed) {
                        setSites([].concat(sites));
                    }
                })

            buildDestinations();
        });
    }, [sites]);

    useEffect(() => {

        if (filterOptions) {
            return;
        }

        auth.getRequest('/bookings/find-options')
            .then(response => {
                setFilterOptions(response.data)
            })
    }, [filterOptions]);

    useEffect(() => {
        buildDestinations();
    }, [sites]);

    useEffect(() => {
        layout.setAllowScrollTop(false)

        const timeout = setTimeout(_ => setLoading(false), 20);

        if (sites === null) {
            getSites(query);
        }


        return _ => {
            layout.setAllowScrollTop(true)
            clearTimeout(timeout)
        };
    }, []);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(
                (_) => showPositionRef.current && showPositionRef.current(_),
                (_) => locationErrorRef.current && locationErrorRef.current(_),
                {
                    enableHighAccuracy: false,
                    maximumAge: 300000,
                    timeout: 5000,
                });
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }, []);

    useEffect(() => {
        if (sites)
            history.replace(route.pathname, {
                sites, filterOptions, defaultProps, query
            });
    }, [sites, filterOptions, defaultProps, query]);

    const locationErrorRef = useRef(null);

    const locationError = useCallback(() => {
        setLoadDistanceMatrix(false);

        console.log('locationError');
    });

    locationErrorRef.current = locationError;

    const showPositionRef = useRef(null);

    const showPosition = useCallback((position) => {
        let center = parseCoordinate(position.coords);
        let setLng = position.coords.longitude;

        if (
            !currentLocation ||
            Math.abs(currentLocation.lat - center.lat) > 0.0001 ||
            Math.abs(currentLocation.lng - center.lng) > 0.0001
        ) {
            if (!defaultProps) {
                setDefaultProps({
                    center,
                    zoom: 6
                });

                if (map) {
                    map.setZoom(6);
                    map.panTo(center);
                }
            }

            setCurrentLocation(center);

            buildDestinations();
        }
    }, [currentLocation, map, defaultProps]);

    showPositionRef.current = showPosition;

    const openSiteCard = (id, action) => {
        var site;

        if (site = sites.find(_ => _.id == id)) {
            if (window.innerWidth <= 768) {
                history.push(`/bookings/site-card/${site.id}`, {
                    site,
                    from: props.location.pathname
                })
            } else {
                site.open = action === 'OPEN';
                setSites([].concat(sites));
            }
        }
    }

    if (!filterOptions || !sites || loading) {
        return <LoadingPage/>;
    }

    const filteredSites = sites.filter(site => {
        if (query.max_journey_duration) {
            return site.journey && ((site.journey.duration.value <= query.max_journey_duration) || (query.max_journey_duration == '9+' && site.journey.duration.value > 3600 * 9));
        }


        return true;
    });

    return (
        <Card
            className={`mx-2 my-2 p-2 site-finder site-find-card position-relative ${listView ? 'list-view' : 'map-view'} ${showFilter ? 'show-filter' : ''}`}>
            <Helmet>
                <title>{t('drawer_link_titles.find_site')} - {t('app')}</title>
            </Helmet>

            <Card.Subtitle>
                <Row>
                    <Col cols="12" className="d-flex justify-content-between">
                        <h4 className="page-title">
                            {!siteNameSearch ? t('drawer_link_titles.find_site') : t('find-site.results_for')}
                            {siteNameSearch && <span className="text-primary"> "{siteNameSearch}"</span>}
                        </h4>

                        <div className="view-controls p-3 p-md-0">
                            <Button variant="success" className="mx-1"
                                    onClick={() => setListView(!listView)}>{listView ? t('map_view') : t('list_view')}</Button>
                            <Button variant="primary" className="mx-1 d-lg-none"
                                    onClick={() => setShowFilter(!showFilter)}>{t('filters_labels.filters')}</Button>
                        </div>
                    </Col>
                </Row>
                {listView &&
                    <Row>
                        <Col cols="12" className="d-flex d-lg-none justify-content-between">
                            <div className="input-group my-3">
                                <Form.Control
                                    placeholder={t('dashboard.driver.search_site')}
                                    className="shadow-none"
                                    type="text"
                                    aria-describedby="button-search"
                                    onInput={(ev) => setSearchQuery(ev.target.value)}
                                ></Form.Control>
                                <Button type="button" id="button-search" onClick={() => onFilter({site_name: searchQuery})}>
                                    <i className="bi bi-search"></i>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                }

                <SiteFilter
                    options={filterOptions}
                    onlySales={route.state && route.state.query && route.state.query.only_sales}
                    values={query}
                    listView={listView}
                    onSubmit={onFilter}
                    onClose={_ => setShowFilter(false)}
                    enableDuration={!!currentLocation}/>

            </Card.Subtitle>
            <div className="map-card-content">

                {
                    loadDistanceMatrix && currentLocation && isLoaded &&
                        <ErrorBoundary level={LEVEL_ERROR} fallbackUI={({error, resetError}) => <></>}>
                    <DistanceMatrixServiceComponent destinations={destinations} origins={currentLocation}
                                                    updateDistanceInformation={updateDistanceInformation}/>
                        </ErrorBoundary>

                }

                {listView && (
                    <Row className="pt-3 list-view-container">
                        {
                            filteredSites.map((site, index) => {
                                return (
                                    <Col className="col-12 bg-gray-100 p-2" sm={6} md={4} key={index}>
                                        <SiteCard site={site} currentLocation={currentLocation} view="listView"/>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                ) || isLoaded && (
                        <ErrorBoundary level={LEVEL_ERROR} fallbackUI={({error, resetError}) => <MapError />}>
                        <div style={{width: '100%'}}>
                            <GoogleMap
                                className="mt-2"
                                mapContainerStyle={{
                                    height: "100%",
                                    width: "100%"
                                }}
                                options={{gestureHandling: "greedy"}}
                                onLoad={onMapLoaded}
                                onUnmount={onMapUnload}
                                onIdle={onMapIdle}
                            >
                                {map && (
                                    <>
                                        {filteredSites.map((site, index) =>
                                            <Marker
                                                onClick={() => openSiteCard(site.id, 'OPEN')}
                                                key={site.id}
                                                icon={window.google && {
                                                    url: '/images/pin.svg',
                                                    scaledSize: new window.google.maps.Size(20, 32),
                                                    size: new window.google.maps.Size(20, 32),
                                                    origin: new window.google.maps.Point(0, 0),
                                                    anchor: new window.google.maps.Point(10, 32),

                                                }}
                                                position={{
                                                    lat: parseFloat(site.latitude),
                                                    lng: parseFloat(site.longitude)
                                                }}
                                                zIndex={99}
                                            >
                                                {
                                                    (site.open && window.innerWidth > 768) &&
                                                    <InfoWindow
                                                        key={index}
                                                        onCloseClick={() => openSiteCard(site.id, 'CLOSE')}
                                                        position={{
                                                            lat: parseFloat(site.latitude),
                                                            lng: parseFloat(site.longitude)
                                                        }}>
                                                        <SiteMapCard site={site} view="map"
                                                                     key={`${index}-${site.id}`}/>
                                                    </InfoWindow>
                                                }
                                            </Marker>
                                        )}

                                        {currentLocation && (
                                            <Marker
                                                icon={window.google && {
                                                    url: '/images/truck.svg',
                                                    scaledSize: new window.google.maps.Size(40, 30),
                                                    size: new window.google.maps.Size(40, 30),
                                                    origin: new window.google.maps.Point(0, 0),
                                                    anchor: new window.google.maps.Point(20, 15),

                                                }}
                                                position={currentLocation}
                                                zIndex={100}
                                            />
                                        )}
                                    </>
                                )}
                            </GoogleMap>
                        </div>
                        </ErrorBoundary>
                    ) || ''
                }
            </div>
        </Card>
    )
}

const MapError = () => {
    const { t } = useTranslation();

    return (
        <div className="text-center" >
            <i className="bi bi-emoji-frown" style={{fontSize: "10rem"}} />
            <p>
                {t('errors.map_error')}
            </p>
        </div>
    );
}

export default FindSite;
